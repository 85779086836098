<template>
  <div class="app">
    <template v-if="currentRoute.meta.template === 'login'">
      <router-view/>
    </template>
    <div class="template-with-sidebar" v-else>
      <app-sidebar class="sidebar"/>
      <div class="main">
        <app-header class="header"/>
        <router-view class="view"/>
<!--        <app-footer class="footer"/>-->
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import AppSidebar from './layouts/AppSidebar'
import AppHeader from './layouts/AppHeader'
// import AppFooter from './layouts/AppFooter'
import auth from '@/store/auth'

export default {
  components: {
    AppSidebar,
    AppHeader
    // AppFooter
  },
  setup () {
    auth().restore()
    const router = useRouter()
    return {
      currentRoute: router.currentRoute
    }
  }
}
</script>

<style lang="scss">
.template-with-sidebar {
  position: relative;
  > .sidebar {
    height: 100vh;
    width: 230px;
    position: fixed;
  }
  > .main {
    position: absolute;
    left: 230px;
    width: calc(100% - 230px);
  }
  > .main > .header {
    height: 60px;
  }
  > .main > .view {
    min-height: calc(100vh - 125px);
    box-sizing: border-box;
    padding: 20px;
  }
  > .main > .footer {
    height: 60px;
  }
}
</style>
