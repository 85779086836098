
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import auth from '@/store/auth'
import ApiClient from '@/plugins/ApiClient'

const state = reactive({
  email: '',
  password: ''
})

const error = ref('')

export default {
  setup () {
    const router = useRouter()
    const login = () => {
      // TODO: ローディング開始
      ApiClient.login({
        email: state.email,
        password: state.password
      }).then(response => {
        auth().setToken(response.data.api_token)
        router.push({ name: 'DashboardPage' })
      }).catch(() => {
        error.value = 'メールアドレスかパスワードに誤りがあります。'
      }).finally(() => {
        // TODO: ローディング終了
      })
    }
    return {
      state,
      error,
      login,
      resetError: () => {
        error.value = ''
      }
    }
  }
}
