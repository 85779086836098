<template>
  <div>
    <table class="app-table">
      <thead>
        <tr>
          <th>注文番号</th>
          <th>会員名</th>
          <th>送付先都道府県</th>
          <th>合計金額</th>
          <th>注文日時</th>
        </tr>
      </thead>
      <tbody>
      <tr class="-clickable" v-for="order in orders" :key="order.id" @click="goDetail(order.id)">
        <td>{{ order.id }}</td>
        <td>{{ `${order.family_name || ''} ${order.first_name || ''}` }}</td>
        <td>{{ getPrefectureName(order.pref) }}</td>
        <td>{{ price(order.total_price) }}</td>
        <td>{{ order.created_at ? DateTime.fromISO(order.created_at).toFormat('yyyy/MM/dd HH:mm:ss') : '-' }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'
import { getPrefectureName } from '@/data/prefectures'

const orders = ref({})

export default {
  setup () {
    const router = useRouter()
    const goDetail = (id: number) => {
      router.push({ name: 'ShippingDetailPage', params: { id } })
    }
    ApiClient.ordersGet().then(response => {
      orders.value = response.data
    })
    return {
      goDetail,
      DateTime,
      getPrefectureName,
      orders,
      price: (price: any) => price === undefined ? '-' : '￥' + price.toLocaleString()
    }
  }
}
</script>

<style lang="scss">
</style>
