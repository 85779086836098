import { ref } from 'vue'

const title = ref('')
const name = ref('')

/* setters */
const setTitle = (newTitle: string) => title.value = newTitle
const setName = (newName: string) => name.value = newName

/* getters */
const getTitle = (): string => title.value
const getName = (): string => name.value

export default () => {
  return {
    setTitle,
    setName,
    getTitle,
    getName
  }
}
