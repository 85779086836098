<template>
  <div class="login-page">
    <p class="logo"><img src="@/assets/logo.png" alt=""></p>
    <form class="form" @submit.prevent="login">
      <p>メールアドレス</p>
      <p><input type="text" v-model="state.email" @keydown="resetError"></p>
      <p>パスワード</p>
      <p><input type="password" v-model="state.password" @keydown="resetError"></p>
      <p class="submit"><button>ログイン</button></p>
      <p class="error" v-if="error !== ''">{{error}}</p>
    </form>
  </div>
</template>

<script lang="ts">
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import auth from '@/store/auth'
import ApiClient from '@/plugins/ApiClient'

const state = reactive({
  email: '',
  password: ''
})

const error = ref('')

export default {
  setup () {
    const router = useRouter()
    const login = () => {
      // TODO: ローディング開始
      ApiClient.login({
        email: state.email,
        password: state.password
      }).then(response => {
        auth().setToken(response.data.api_token)
        router.push({ name: 'DashboardPage' })
      }).catch(() => {
        error.value = 'メールアドレスかパスワードに誤りがあります。'
      }).finally(() => {
        // TODO: ローディング終了
      })
    }
    return {
      state,
      error,
      login,
      resetError: () => {
        error.value = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 8%;
  > .logo {
    width: 100px;
  }
  > .form {
    display: block;
    width: 320px;
    margin-top: 20px;
    border-radius: 4px;
    padding: 25px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 0 3px 0 rgba(#000, .1);
  }
  > .form > .submit {
    display: flex;
    padding-top: 5px;
    justify-content: center;
  }
  > .form > .error {
    color: #d00;
    margin-top: 16px;
  }
}
</style>
