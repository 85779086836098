
import { useRouter } from 'vue-router'
import { computed } from 'vue'

const menu = [
  {
    name: 'DashboardPage',
    label: 'ダッシュボード',
    icon: 'dashboard'
  },
  {
    name: 'CustomersPage',
    label: '会員一覧',
    icon: 'member'
  },
  {
    name: 'ShippingPage',
    label: '注文一覧',
    icon: 'shipping'
  },
  {
    name: 'ContactsPage',
    label: 'お問い合わせ履歴',
    icon: 'contact'
  },
  {
    name: 'EstimatesPage',
    label: 'お見積り依頼履歴',
    icon: 'inquiry'
  }
]

export default {
  setup () {
    const router = useRouter()
    const routeName = computed(() => {
      return router.currentRoute.value.name
    })
    return {
      routeName,
      menu
    }
  }
}
