<template>
  <div>
    <table class="app-table">
      <thead>
      <tr>
        <th>会員番号</th>
        <th>氏名</th>
        <th>都道府県</th>
        <th>登録日</th>
        <th>最終ログイン日時</th>
      </tr>
      </thead>
      <tbody>
      <tr class="-clickable" @click="goDetail(customer.id)" v-for="customer in customers" :key="customer.id">
        <td>{{ customer.id }}</td>
        <td>{{ customer.family_name }} {{ customer.first_name }}</td>
        <td>{{ getPrefectureName(customer.pref) }}</td>
        <td>{{ DateTime.fromISO(customer.created_at).toFormat('yyyy年MM月dd日') }}</td>
        <td>{{ customer.last_login_at ? DateTime.fromISO(customer.last_login_at).toFormat('yyyy年MM月dd日') : '-' }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'
import { getPrefectureName } from '@/data/prefectures'

const customers = ref(null)

export default {
  setup () {
    const router = useRouter()
    const goDetail = (id: number) => {
      router.push({ name: 'CustomersDetailPage', params: { id } })
    }
    ApiClient.customersGet().then(response => {
      customers.value = response.data
    })
    return {
      DateTime,
      customers,
      getPrefectureName,
      goDetail
    }
  }
}
</script>
