<template>
  <div>
    <table class="app-table">
      <thead>
      <tr>
        <th>お見積り番号</th>
        <th>会社名</th>
        <th>お名前</th>
        <th>施工内容</th>
        <th>施工現場名</th>
        <th>依頼日時</th>
      </tr>
      </thead>
      <tbody>
      <tr class="-clickable" @click="goDetail(estimate.id)" v-for="estimate in estimates" :key="estimate.id">
        <td>{{ estimate.id }}</td>
        <td>{{ estimate.company_name }}</td>
        <td>{{ estimate.name }}</td>
        <td>{{ estimate.content }}</td>
        <td>{{ estimate.spot_name }}</td>
        <td>{{ estimate.created_at ? DateTime.fromISO(estimate.created_at).toFormat('yyyy-MM-dd HH:mm:ss') : '-' }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'

const estimates = ref([])

export default {
  setup () {
    const router = useRouter()
    const goDetail = (id) => {
      router.push({
        name: 'EstimatesDetailPage',
        params: { id }
      })
    }
    ApiClient.estimatesGet().then(response => {
      estimates.value = response.data
    })
    return {
      estimates,
      goDetail,
      DateTime
    }
  }
}
</script>
