
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'
import { getPrefectureName } from '@/data/prefectures'

const orders = ref({})

export default {
  setup () {
    const router = useRouter()
    const goDetail = (id: number) => {
      router.push({ name: 'ShippingDetailPage', params: { id } })
    }
    ApiClient.ordersGet().then(response => {
      orders.value = response.data
    })
    return {
      goDetail,
      DateTime,
      getPrefectureName,
      orders,
      price: (price: any) => price === undefined ? '-' : '￥' + price.toLocaleString()
    }
  }
}
