<template>
  <div>
    <p class="header">注文概要</p>
    <div class="app-card">
      <div class="row">
        <p class="label">注文番号</p>
        <p class="value">{{ order.id }}</p>
      </div>
      <div class="row">
        <p class="label">注文日時</p>
        <p class="value">{{ order.created_at ? DateTime.fromISO(customer.created_at).toFormat('yyyy年MM月dd日') : '-' }}</p>
      </div>
      <div class="row">
        <p class="label">購入者</p>
        <p class="value">{{ customer.family_name }} {{ customer.first_name }}</p>
      </div>
      <div class="row">
        <p class="label">商品金額（税込）</p>
        <p class="value">{{ price(order.total_price) }}</p>
      </div>
      <div class="row">
        <p class="label">送料</p>
        <p class="value">{{ price(order.postage) }}</p>
      </div>
      <div class="row">
        <p class="label">お支払い金額合計</p>
        <p class="value">{{ price(order.total_price_with_postage) }}</p>
      </div>
    </div>

    <p class="header">注文明細</p>
    <table class="app-table">
      <thead>
      <tr>
        <th>No</th>
        <th>商品名</th>
        <th>オプション</th>
        <th>単価</th>
        <th>数量</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, key) in order.details" :key="key">
        <td>{{ key + 1 }}</td>
        <td>{{ item.name }}</td>
        <td>
          <div v-if="item.vertical">縦幅：{{ item.vertical }} mm</div>
          <div v-if="item.horizontal">横幅：{{ item.horizontal }} mm</div>
          <div v-if="item.thickness">厚み：{{ item.thickness }} mm</div>
          <div v-if="item.size">サイズ：{{ item.size }}</div>
          <div v-if="item.color">カラー：{{ item.color }}</div>
          <div v-if="item.protection">保護剤：{{ item.protection }}</div>
          <div v-if="item.back">天板裏側仕上げ：{{ item.back }}</div>
          <div v-if="item.radius">テーブル角のR加工：{{ item.radius }}</div>
        </td>
        <td>{{ item.amount }}</td>
        <td>{{ price(item.priceWithOption * 1.1 * item.amount) }}</td>
      </tr>
      </tbody>
    </table>

    <p class="header">配送先情報</p>
    <div class="app-card">
      <div class="row">
        <p class="label">宛名</p>
        <p class="value">{{ order.family_name }} {{ order.first_name }}</p>
      </div>
      <div class="row">
        <p class="label">フリガナ</p>
        <p class="value">{{ order.family_name_kana }} {{ order.first_name_kana }}</p>
      </div>
      <div class="row">
        <p class="label">郵便番号</p>
        <p class="value">{{ order.postal_code }}</p>
      </div>
      <div class="row">
        <p class="label">住所</p>
        <p class="value">{{ getAddress() }}</p>
      </div>
      <div class="row">
        <p class="label">電話番号</p>
        <p class="value">{{ order.tel }}</p>
      </div>
      <div class="row">
        <p class="label">FAX番号</p>
        <p class="value">{{ order.fax }}</p>
      </div>
      <div class="row" style="white-space: pre;">
        <p class="label">備考</p>
        <p class="value">{{ order.notice }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'
import { getPrefectureName } from '@/data/prefectures'

const order = ref({})
const customer = ref({})

export default {
  setup () {
    const router = useRouter()
    const id = parseInt(router.currentRoute.value.params.id + '')
    ApiClient.ordersDetailGet(id).then(response => {
      order.value = response.data.order
      customer.value = response.data.customer
    })
    return {
      order,
      customer,
      DateTime,
      getAddress: () => {
        return order.value
          ? `${getPrefectureName(order.value.pref)} ${order.value.city} ${order.value.street} ${order.value.building}`
          : ''
      },
      price: (price) => price === undefined ? '-' : '￥' + price.toLocaleString()
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 10px;
  ~ .header {
    margin-top: 20px;
  }
}
</style>
