import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import page from '@/store/page'
import auth from '@/store/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'LoginPage',
    component: require('../views/LoginPage/_index').default,
    meta: {
      template: 'login'
    }
  },
  {
    path: '/',
    name: 'DashboardPage',
    component: require('../views/DashboardPage/_index').default,
    meta: {
      auth: true,
      title: 'ダッシュボード'
    }
  },
  {
    path: '/customers',
    name: 'CustomersPage',
    component: require('../views/CustomersPage/_index').default,
    meta: {
      auth: true,
      title: '会員一覧'
    }
  },
  {
    path: '/customers/:id',
    name: 'CustomersDetailPage',
    component: require('../views/CustomersDetailPage/_index').default,
    meta: {
      auth: true,
      title: '会員詳細'
    }
  },
  {
    path: '/shipping',
    name: 'ShippingPage',
    component: require('../views/ShippingPage/_index').default,
    meta: {
      auth: true,
      title: '注文一覧'
    }
  },
  {
    path: '/shipping/:id',
    name: 'ShippingDetailPage',
    component: require('../views/ShippingDetailPage/_index').default,
    meta: {
      auth: true,
      title: '注文詳細'
    }
  },
  {
    path: '/contacts',
    name: 'ContactsPage',
    component: require('../views/ContactsPage/_index').default,
    meta: {
      auth: true,
      title: 'お問い合わせ履歴'
    }
  },
  {
    path: '/contacts/:id',
    name: 'ContactsDetailPage',
    component: require('../views/ContactsDetailPage/_index').default,
    meta: {
      auth: true,
      title: 'お問い合わせ詳細'
    }
  },
  {
    path: '/estimates',
    name: 'EstimatesPage',
    component: require('../views/EstimatesPage/_index').default,
    meta: {
      auth: true,
      title: 'お見積り依頼履歴'
    }
  },
  {
    path: '/estimates/:id',
    name: 'EstimatesDetailPage',
    component: require('../views/EstimatesDetailPage/_index').default,
    meta: {
      auth: true,
      title: 'お見積り依頼詳細'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: (to, from, savedPosition) => {
    savedPosition
      ? window.scroll(savedPosition.left, savedPosition.top)
      : window.scroll(0, 0)
  },
  routes
})

router.beforeEach((to, from, next) => {
  page().setTitle(to.meta.title)

  if (to.matched.some(meta => meta.meta.auth)) {
    if (!auth().getToken()) {
      next({
        name: 'LoginPage',
        params: {
          message: 'ログインしてください。'
        }
      })
    }
  }

  next()
})

export default router
