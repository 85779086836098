<template>
  <div class="app-sidebar">
    <div class="head">
      <p class="logo"></p>
    </div>
    <nav class="navigation">
      <template v-for="(item, key) in menu" :key="key">
        <router-link :to="{name: item.name}" class="item" :class="{'-current': routeName === item.name}">
          <span class="icon"><app-icon :name="item.icon"/></span>
          <span class="name">{{ item.label }}</span>
        </router-link>
      </template>
    </nav>
  </div>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'
import { computed } from 'vue'

const menu = [
  {
    name: 'DashboardPage',
    label: 'ダッシュボード',
    icon: 'dashboard'
  },
  {
    name: 'CustomersPage',
    label: '会員一覧',
    icon: 'member'
  },
  {
    name: 'ShippingPage',
    label: '注文一覧',
    icon: 'shipping'
  },
  {
    name: 'ContactsPage',
    label: 'お問い合わせ履歴',
    icon: 'contact'
  },
  {
    name: 'EstimatesPage',
    label: 'お見積り依頼履歴',
    icon: 'inquiry'
  }
]

export default {
  setup () {
    const router = useRouter()
    const routeName = computed(() => {
      return router.currentRoute.value.name
    })
    return {
      routeName,
      menu
    }
  }
}
</script>

<style lang="scss" scoped>
.app-sidebar {
  box-sizing: border-box;
  color: #ccc;
  background: linear-gradient(#34495e, rgba(#34495e, .85));
  > .head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background-color: #2c3e50;
  }
  > .head > .logo {
    background: url(~@/assets/logo.png) no-repeat;
    background-size: cover;
    width: 60px;
    height: 36px;
  }
  > .navigation > .item {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    transition: .3s;
    text-decoration: none;
    position: relative;
    &.-current,
    &:hover {
      color: #fff;
      //background-color: #2c3e50;
    }
    &.-current::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 0;
      border: 8px solid transparent;
      border-right-color: $COLOR_BG;
    }
  }
  > .navigation > .item > .icon {
    display: block;
    width: 22px;
    height: 22px;
  }
  > .navigation > .item > .name {
    margin-left: 12px;
  }
}
</style>
