
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'
import { getPrefectureName } from '@/data/prefectures'

const customers = ref(null)

export default {
  setup () {
    const router = useRouter()
    const goDetail = (id: number) => {
      router.push({ name: 'CustomersDetailPage', params: { id } })
    }
    ApiClient.customersGet().then(response => {
      customers.value = response.data
    })
    return {
      DateTime,
      customers,
      getPrefectureName,
      goDetail
    }
  }
}
