<template>
  <div class="app-header">
    <p class="title">{{title()}}</p>
    <p class="logout" @click="logout">ログアウト</p>
  </div>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'
import page from '@/store/page'

export default {
  setup () {
    const router = useRouter()
    const logout = () => {
      router.push({ name: 'LoginPage' })
    }
    return {
      logout,
      title: page().getTitle
    }
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 0 3px 0 rgba(#000, .1);
  background-color: #fff;
  box-sizing: border-box;
  > .title {
    font-size: 20px;
  }
  > .logout {
    transition: .3s;
    color: #999;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
}
</style>
