
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'
import { getPrefectureName } from '@/data/prefectures'

const customer = ref({})
const orders = ref([])

export default {
  setup () {
    const router = useRouter()
    const id = parseInt(router.currentRoute.value.params.id + '')
    const goOrdersDetail = (id: number) => {
      router.push({ name: 'ShippingDetailPage', params: { id } })
    }
    ApiClient.customersDetailGet(id).then(response => {
      customer.value = response.data.profile
      orders.value = response.data.orders
    })
    return {
      DateTime,
      goOrdersDetail,
      getPrefectureName,
      customer,
      orders,
      price: (price: any) => price === undefined ? '-' : '￥' + price.toLocaleString()
    }
  }
}
