
import { useRouter } from 'vue-router'
import page from '@/store/page'

export default {
  setup () {
    const router = useRouter()
    const logout = () => {
      router.push({ name: 'LoginPage' })
    }
    return {
      logout,
      title: page().getTitle
    }
  }
}
