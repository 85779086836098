<template>
  <div>
    <div class="app-card">
      <div class="row">
        <p class="label">お問合せ番号</p>
        <p class="value">{{ contact.id }}</p>
      </div>
      <div class="row">
        <p class="label">お名前</p>
        <p class="value">{{ contact.name }}</p>
      </div>
      <div class="row">
        <p class="label">メッセージ本文</p>
        <p class="value" style="white-space: pre;">{{ contact.body }}</p>
      </div>
      <div class="row">
        <p class="label">お問合せ日時</p>
        <p class="value">{{ contact.created_at ? DateTime.fromISO(contact.created_at).toFormat('yyyy-MM-dd HH:mm:ss') : '-' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'

const contact = ref({})

export default {
  setup () {
    const router = useRouter()
    const id = parseInt(router.currentRoute.value.params.id + '')
    ApiClient.contactsDetailGet(id).then(response => {
      contact.value = response.data
    })
    return {
      DateTime,
      contact
    }
  }
}
</script>
