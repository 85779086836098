<template>
  <div>
    <table class="app-table">
      <thead>
      <tr>
        <th>お問合せ番号</th>
        <th>お名前</th>
        <th>題名</th>
        <th>お問合せ日時</th>
      </tr>
      </thead>
      <tbody>
      <tr class="-clickable" @click="goDetail(contact.id)" v-for="contact in contacts" :key="contact.id">
        <td>{{ contact.id }}</td>
        <td>{{ contact.name }}</td>
        <td>{{ contact.title }}</td>
        <td>{{ contact.created_at ? DateTime.fromISO(contact.created_at).toFormat('yyyy-MM-dd HH:mm:ss') : '-' }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'

const contacts = ref([])

export default {
  setup () {
    const router = useRouter()
    const goDetail = (id) => {
      router.push({
        name: 'ContactsDetailPage',
        params: { id }
      })
    }
    ApiClient.contactsGet().then(response => {
      contacts.value = response.data
    })
    return {
      contacts,
      goDetail,
      DateTime
    }
  }
}
</script>
