import { reactive } from 'vue'

const auth = reactive({
  token: ''
})

/* setters */
const setToken = (newToken: string): void => {
  auth.token = newToken
  localStorage.setItem('auth', JSON.stringify(auth))
}

/* getters */
const getToken = (): string => auth.token

const restore = (): void => {
  const authJson = localStorage.getItem('auth')
  if (!authJson) { return }
  const restoredAuth = JSON.parse(authJson)
  auth.token = restoredAuth.token
}

export default () => {
  return {
    setToken,
    getToken,
    restore
  }
}
