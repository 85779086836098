<template>
  <div>
    <p class="header">基本情報</p>
    <div class="app-card">
      <div class="row">
        <p class="label">会員番号</p>
        <p class="value">{{ customer.id }}</p>
      </div>
      <div class="row">
        <p class="label">氏名</p>
        <p class="value">{{ `${customer.family_name || ''} ${customer.first_name || ''}` }}</p>
      </div>
      <div class="row">
        <p class="label">氏名（カナ）</p>
        <p class="value">{{ `${customer.family_name_kana || ''} ${customer.first_name_kana || ''}` }}</p>
      </div>
      <div class="row">
        <p class="label">郵便番号</p>
        <p class="value">{{ customer.postal_code || '' }}</p>
      </div>
      <div class="row">
        <p class="label">住所</p>
        <p class="value">{{ `${getPrefectureName(customer.pref) || ''} ${customer.city || ''} ${customer.street || ''} ${customer.building || ''}` }}</p>
      </div>
      <div class="row">
        <p class="label">電話番号</p>
        <p class="value">{{ customer.tel }}</p>
      </div>
      <div class="row">
        <p class="label">FAX番号</p>
        <p class="value">{{ customer.fax }}</p>
      </div>
      <div class="row">
        <p class="label">登録日時</p>
        <p class="value">{{ customer.created_at ? DateTime.fromISO(customer.created_at).toFormat('yyyy/MM/dd HH:mm:ss') : '-' }}</p>
      </div>
      <div class="row">
        <p class="label">最終ログイン日時</p>
        <p class="value">{{ customer.last_login_at ? DateTime.fromISO(customer.last_login_at).toFormat('yyyy/MM/dd HH:mm:ss') : '-' }}</p>
      </div>
    </div>

    <p class="header">購入履歴</p>
    <table v-if="orders.length" class="app-table">
      <thead>
      <tr>
        <th>注文番号</th>
        <th>送付先都道府県</th>
        <th>合計金額</th>
        <th>注文日時</th>
      </tr>
      </thead>
      <tbody>
      <tr class="-clickable" v-for="(order, key) in orders" :key="key" @click="goOrdersDetail(order.id)">
        <td>{{ order.id }}</td>
        <td>{{ getPrefectureName(order.pref) }}</td>
        <td>{{ price(order.total_price) }}</td>
        <td>{{ order.created_at ? DateTime.fromISO(order.created_at).toFormat('yyyy/MM/dd HH:mm:ss') : '-' }}</td>
      </tr>
      </tbody>
    </table>
    <p v-else class="app-card">購入履歴はありません</p>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'
import { getPrefectureName } from '@/data/prefectures'

const customer = ref({})
const orders = ref([])

export default {
  setup () {
    const router = useRouter()
    const id = parseInt(router.currentRoute.value.params.id + '')
    const goOrdersDetail = (id: number) => {
      router.push({ name: 'ShippingDetailPage', params: { id } })
    }
    ApiClient.customersDetailGet(id).then(response => {
      customer.value = response.data.profile
      orders.value = response.data.orders
    })
    return {
      DateTime,
      goOrdersDetail,
      getPrefectureName,
      customer,
      orders,
      price: (price: any) => price === undefined ? '-' : '￥' + price.toLocaleString()
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 10px;
  ~ .header {
    margin-top: 20px;
  }
}
</style>
