<template>
  <div class="dashboard-page">
    <p class="header">2021/01</p>
    <div class="row">
      <div class="app-card">
        <p class="number"><app-icon class="icon" name="member"/>+1</p>
        <p class="label">会員数</p>
      </div>
      <div class="app-card">
        <p class="number"><app-icon class="icon" name="shipping"/>+1</p>
        <p class="label">注文件数</p>
      </div>
      <div class="app-card">
        <p class="number"><app-icon class="icon" name="contact"/>+4</p>
        <p class="label">お問い合わせ件数</p>
      </div>
      <div class="app-card">
        <p class="number"><app-icon class="icon" name="inquiry"/>+2</p>
        <p class="label">お見積り依頼件数</p>
      </div>
    </div>
    <p class="header">2020/12</p>
    <div class="row">
      <div class="app-card">
        <p class="number"><app-icon class="icon" name="member"/>+1</p>
        <p class="label">会員数</p>
      </div>
      <div class="app-card">
        <p class="number"><app-icon class="icon" name="shipping"/>+0</p>
        <p class="label">注文件数</p>
      </div>
      <div class="app-card">
        <p class="number"><app-icon class="icon" name="contact"/>+0</p>
        <p class="label">お問い合わせ件数</p>
      </div>
      <div class="app-card">
        <p class="number"><app-icon class="icon" name="inquiry"/>+0</p>
        <p class="label">お見積り依頼件数</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-page {
  > .row {
    display: flex;
    margin-bottom: 30px;
  }
}

.app-card {
  width: 200px;
  margin-right: 20px;
  > .label {
    color: #999;
    text-align: right;
  }
  > .number {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    text-align: right;
    font-size: 28px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  > .number > .icon {
    width: 32px;
    height: 32px;
    color: #888;
  }
}

.header {
  margin-bottom: 10px;
  font-size: 18px;
}
</style>
