<template>
  <div>
    <div class="app-card">
      <div class="row">
        <p class="label">お見積り依頼番号</p>
        <p class="value">{{ estimate.id }}</p>
      </div>
      <div class="row">
        <p class="label">会社名</p>
        <p class="value">{{ estimate.company_name }}</p>
      </div>
      <div class="row">
        <p class="label">お名前</p>
        <p class="value">{{ estimate.name }}</p>
      </div>
      <div class="row">
        <p class="label">メールアドレス</p>
        <p class="value">{{ estimate.email }}</p>
      </div>
      <div class="row">
        <p class="label">施工現場名</p>
        <p class="value">{{ estimate.spot_name }}</p>
      </div>
      <div class="row">
        <p class="label">施工現場住所</p>
        <p class="value">{{ estimate.spot_address }}</p>
      </div>
      <div class="row">
        <p class="label">施工内容</p>
        <p class="value">{{ estimate.content }}</p>
      </div>
      <div class="row">
        <p class="label">施工平米数、サイズ等</p>
        <p class="value">{{ estimate.size }}</p>
      </div>
      <div class="row">
        <p class="label">カラー</p>
        <p class="value">{{ estimate.color }}</p>
      </div>
      <div class="row">
        <p class="label">汚れ保護剤</p>
        <p class="value">{{ estimate.protection }}</p>
      </div>
      <div class="row">
        <p class="label">施工時期</p>
        <p class="value">{{ estimate.season }}</p>
      </div>
      <div class="row">
        <p class="label">メッセージ本文</p>
        <p class="value" style="white-space: pre;">{{ estimate.body }}</p>
      </div>
      <div class="row">
        <p class="label">お見積り依頼日時</p>
        <p class="value">{{ estimate.created_at ? DateTime.fromISO(estimate.created_at).toFormat('yyyy-MM-dd HH:mm:ss') : '-' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ApiClient from '@/plugins/ApiClient'

const estimate = ref({})

export default {
  setup () {
    const router = useRouter()
    const id = parseInt(router.currentRoute.value.params.id + '')
    ApiClient.estimatesDetailGet(id).then(response => {
      estimate.value = response.data
    })
    return {
      DateTime,
      estimate
    }
  }
}
</script>
