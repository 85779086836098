import axios from 'axios'
import auth from '@/store/auth'

const url = (path: string) => {
  return process.env.VUE_APP_API_BASE_URL + path
}

export default {
  login (params: object) {
    return axios({
      method: 'post',
      url: url('/login'),
      data: params
    })
  },
  customersGet () {
    return axios({
      method: 'get',
      url: url('/customers'),
      headers: {
        Authorization: 'Bearer ' + auth().getToken()
      }
    })
  },
  customersDetailGet (id: number) {
    return axios({
      method: 'get',
      url: url('/customers/' + id),
      headers: {
        Authorization: 'Bearer ' + auth().getToken()
      }
    })
  },
  ordersGet () {
    return axios({
      method: 'get',
      url: url('/orders'),
      headers: {
        Authorization: 'Bearer ' + auth().getToken()
      }
    })
  },
  ordersDetailGet (id: number) {
    return axios({
      method: 'get',
      url: url('/orders/' + id),
      headers: {
        Authorization: 'Bearer ' + auth().getToken()
      }
    })
  },
  contactsGet () {
    return axios({
      method: 'get',
      url: url('/contacts'),
      headers: {
        Authorization: 'Bearer ' + auth().getToken()
      }
    })
  },
  contactsDetailGet (id: number) {
    return axios({
      method: 'get',
      url: url('/contacts/' + id),
      headers: {
        Authorization: 'Bearer ' + auth().getToken()
      }
    })
  },
  estimatesGet () {
    return axios({
      method: 'get',
      url: url('/estimates'),
      headers: {
        Authorization: 'Bearer ' + auth().getToken()
      }
    })
  },
  estimatesDetailGet (id: number) {
    return axios({
      method: 'get',
      url: url('/estimates/' + id),
      headers: {
        Authorization: 'Bearer ' + auth().getToken()
      }
    })
  }
}
